import { useState } from 'react';
import analytics from '@analytics';
import { ArrowRightIcon } from '@heroicons/react/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Button, Typography } from '@leaf/ui';
import { useSubscribeMutation } from '@/apollo/generated';
import { useAlert } from '@/contexts/alert-context';

interface FormData {
  email: string;
  firstName?: string;
  lastName?: string;
}

const SubscribeForm: React.ComponentType<{ event?: string }> = ({ event }) => {
  const { formState, handleSubmit, register } = useForm<FormData>({
    resolver: yupResolver(
      yup.object().shape({
        email: yup.string().trim().email().required(),
        firstName: yup.string().trim(),
        lastName: yup.string().trim(),
      })
    ),
  });

  const [showForm, toggleShowForm] = useState(true);

  const { formatAndShowError, showAlert } = useAlert();

  const [subscribe, { loading }] = useSubscribeMutation();

  const onSubmit = handleSubmit(async (data) => {
    if (event) analytics.track(event);

    subscribe({
      variables: {
        input: {
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
        },
      },
    })
      .then(() => {
        toggleShowForm(false);
        showAlert({
          description: 'Great, you are on the list to receive email updates',
          variant: 'success',
        });
      })
      .catch(formatAndShowError);
  });

  return (
    <form className="bg-transparent" onSubmit={onSubmit}>
      {/* The "xs" styles here are important for when clients embed the "/subscribe" page in an iframe */}
      {/* If you are going to change this form, make sure it still works in this iframe: */}
      {/* <iframe height="290" src="https://{ticker}.localhost.com:3003/subscribe" style="border: none; max-width: 100%" width="520"/> */}
      <div className="h-[235px] xs:h-[185px]">
        {showForm ? (
          <div className="flex flex-col gap-3">
            <div className="grid grid-cols-1 gap-2 xs:grid-cols-2">
              <input
                className="input col-span-1 h-10 min-w-0 rounded-md transition-colors focus:border-gray-200 focus:shadow-none focus:ring-company-primary"
                placeholder="First name"
                type="text"
                {...register('firstName')}
              />
              <input
                className="input col-span-1 h-10 min-w-0 rounded-md transition-colors focus:border-gray-200 focus:shadow-none focus:ring-company-primary"
                placeholder="Last name"
                type="text"
                {...register('lastName')}
              />
              <input
                className={clsx(
                  'input col-span-1 h-10 min-w-0 flex-1 rounded-md transition-colors focus:border-gray-200 focus:shadow-none focus:ring-company-primary xs:col-span-2',
                  !!formState.errors.email && 'input-error'
                )}
                placeholder="Email (required)"
                type="email"
                {...register('email')}
              />
            </div>

            <Button
              color="company"
              disabled={formState.isSubmitting || loading}
              endIcon={<ArrowRightIcon className="size-4" />}
              type="submit"
            >
              Subscribe for our latest news
            </Button>

            <div className="flex items-center gap-x-2 md:self-stretch">
              <Typography className="font-body text-[12px] text-company-accent-text md:text-left">
                Emails are managed by InvestorHub, an investor engagement
                platform. Unsubscribe anytime.
              </Typography>
            </div>
          </div>
        ) : null}
      </div>
    </form>
  );
};

export default SubscribeForm;
