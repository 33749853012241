import React, {
  ComponentType,
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
} from 'react';
import { CurrentCompanyQuery } from '@/apollo/generated';
import RootLayout from '@/components/layouts/root';
import WebsiteLayout from '@/components/website/layout/website-layout';
import Website404 from '@/components/website/page/404';

interface WebsiteContext {
  currentWebsite: NonNullable<
    CurrentCompanyQuery['currentCompany']
  >['publishedWebsite'];
  isPreviewMode: boolean;
  renderContentInLayout: (
    content: React.ReactNode,
    hasContent?: boolean,
    LayoutWithWebsite?: React.FC,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    FallbackLayout?: ComponentType<PropsWithChildren<any>>
  ) => React.JSX.Element | null;
}

const WebsiteContext = createContext<WebsiteContext>({
  currentWebsite: null,
  isPreviewMode: false,
  renderContentInLayout: (_content) => null,
});

export const WebsiteProvider: React.ComponentType<{
  children?: React.ReactNode;
  isPreviewMode: boolean;
  publishedWebsite: NonNullable<
    CurrentCompanyQuery['currentCompany']
  >['publishedWebsite'];
  unpublishedWebsite: NonNullable<
    CurrentCompanyQuery['currentCompany']
  >['unpublishedWebsite'];
}> = ({ children, isPreviewMode, publishedWebsite, unpublishedWebsite }) => {
  const currentWebsite = useMemo(() => {
    if (isPreviewMode && unpublishedWebsite) {
      return unpublishedWebsite;
    }
    if (publishedWebsite) {
      return publishedWebsite;
    }
    return null;
  }, [isPreviewMode, unpublishedWebsite, publishedWebsite]);

  const renderContentInLayout = (
    content: React.ReactNode,
    hasContent = true,
    LayoutWithWebsite = WebsiteLayout,
    FallbackLayout = RootLayout
  ) => {
    const Layout = currentWebsite ? LayoutWithWebsite : FallbackLayout;
    if (!hasContent) {
      return (
        <Layout>
          <Website404 />
        </Layout>
      );
    }
    return <Layout>{content}</Layout>;
  };

  return (
    <WebsiteContext.Provider
      value={{ currentWebsite, isPreviewMode, renderContentInLayout }}
    >
      {children}
    </WebsiteContext.Provider>
  );
};

export const useWebsite = () => useContext(WebsiteContext);
