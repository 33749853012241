import React from 'react';
import analytics from '@analytics';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import * as cookie from 'cookie';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { maybeAddRelNofollow } from '@leaf/helpers';
import { Button, Typography } from '@leaf/ui';
import { useSubscribeMutation } from '@/apollo/generated';
import CloudinaryImage from '@/components/utils/cloudinary/image';
import { useAlert } from '@/contexts/alert-context';
import { formatError } from '@/utils/error/helper';

export const EMAIL_POPUP_CLOSED_COOKIE_NAME = 'email_popup_closed';

interface EmailPopupModalProps {
  companyName: string;
  imageCloudinaryUrl?: string | null;
  isOpen: boolean;
  marketListingKey: string;
  setIsOpen: (isOpen: boolean) => void;
}

const DEFAULT_IMAGE_URL =
  'https://res.cloudinary.com/investorhub-staging/image/upload/v1716859906/dummy_images_for_blocks/news_eo0wzk';

interface FormData {
  email: string;
}

const EmailPopupModal: React.FC<EmailPopupModalProps> = ({
  companyName,
  imageCloudinaryUrl,
  isOpen,
  marketListingKey,
  setIsOpen,
}) => {
  const { showAlert } = useAlert();
  const [subscribe, { loading }] = useSubscribeMutation();

  const { formState, handleSubmit, register, setError } = useForm<FormData>({
    resolver: yupResolver(
      yup.object().shape({
        email: yup.string().trim().email().required(),
      })
    ),
  });

  const setEmailPopupClosedCookie = (isPermanent = false) => {
    const options: cookie.CookieSerializeOptions = {
      path: '/',
    };

    if (!isPermanent) {
      const thirtyDaysFromNow = new Date(Date.now() + 30 * 24 * 60 * 60 * 1000);
      options.expires = thirtyDaysFromNow;
    }

    document.cookie = cookie.serialize(
      EMAIL_POPUP_CLOSED_COOKIE_NAME,
      'true',
      options
    );
  };

  const onSubmit = handleSubmit(async (data) => {
    analytics.track('email_popup_modal_subscribe');

    subscribe({
      variables: {
        input: {
          email: data.email,
        },
      },
    })
      .then(() => {
        setIsOpen(false);
        showAlert({
          description: 'Great, you are on the list to receive email updates',
          variant: 'success',
        });
        setEmailPopupClosedCookie(true); // Set permanent cookie
      })
      .catch((error) => {
        const errorMessage = formatError(error);
        setError('email', { message: errorMessage, type: 'manual' });
      });
  });

  const onClose = () => {
    setIsOpen(false);
    analytics.track('email_popup_modal_closed');
    setEmailPopupClosedCookie(); // Set 30-day cookie
  };

  return (
    <Transition as={React.Fragment} show={isOpen}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="relative w-full max-w-[617px] overflow-hidden rounded-2xl bg-hubs-background text-center align-middle shadow-xl transition-all">
                <button
                  className="absolute right-4 top-4 rounded-full bg-gray-200 p-1 text-gray-600 transition-colors hover:bg-gray-300 hover:text-gray-800"
                  onClick={onClose}
                >
                  <XIcon aria-hidden="true" className="h-5 w-5" />
                </button>

                <CloudinaryImage
                  alt="Stay in the know"
                  className="h-[234px] w-full object-cover"
                  crop="fill"
                  gravity="auto"
                  height={160}
                  src={imageCloudinaryUrl || DEFAULT_IMAGE_URL}
                  width={400}
                />

                <div className="p-8">
                  <Dialog.Title
                    as="h3"
                    className="text-4xl font-semibold leading-6 text-hubs-primary"
                  >
                    Stay in the know
                  </Dialog.Title>
                  <p className="mt-6 text-xl">
                    Sign up for exclusive access to {companyName} updates and
                    news.
                  </p>

                  <div className="mt-6">
                    <form onSubmit={onSubmit}>
                      <div>
                        <div className="flex flex-col items-center gap-3">
                          <div className="flex w-full gap-4">
                            <input
                              className={clsx(
                                'input h-[46px] min-w-0 flex-1 rounded-md transition-colors focus:border-gray-200 focus:shadow-none focus:ring-company-primary',
                                !!formState.errors.email && 'input-error'
                              )}
                              placeholder="Email address"
                              type="email"
                              {...register('email')}
                            />
                            <Button
                              disabled={formState.isSubmitting || loading}
                              size="md"
                              type="submit"
                            >
                              Subscribe
                            </Button>
                          </div>

                          {formState.errors.email && (
                            <Typography className="font-body text-sm text-red-500">
                              {formState.errors.email.message}
                            </Typography>
                          )}

                          <Typography className="text-center font-body text-[12px] text-gray-500">
                            By subscribing, you agree to our{' '}
                            <a
                              className="underline hover:opacity-90"
                              href="https://www.investorhub.com/privacy-centre"
                              rel={`noreferrer ${maybeAddRelNofollow(
                                marketListingKey
                              )}`}
                              target="_blank"
                            >
                              Terms and Conditions
                            </a>{' '}
                            and{' '}
                            <a
                              className="underline hover:opacity-90"
                              href="https://www.investorhub.com/privacy-centre"
                              rel={`noreferrer ${maybeAddRelNofollow(
                                marketListingKey
                              )}`}
                              target="_blank"
                            >
                              Privacy Policy
                            </a>
                            .
                          </Typography>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default EmailPopupModal;
