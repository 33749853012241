'use client';
import { useEffect, useMemo, useState } from 'react';
import analytics from '@analytics';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react-v2/24/outline';
import Link from 'next/link';
import useSWR from 'swr';
import { Typography } from '@leaf/ui';
import { useRefinitivTokenQuery } from '@/apollo/generated';
import PriceChange from '@/components/layouts/root/price-change';
import { useCurrentCompany } from '@/contexts/current-company-context';
import useIsSsr from '@/hooks/use-ssr';
import fetcher, { fetchOptions } from '@/utils/refinitiv/fetcher';
import { getFieldValue } from '@/utils/refinitiv/helper';
import { RefinitivQuoteData } from '@/utils/refinitiv/types';

interface Props {
  showLink?: boolean;
  showListingKey?: boolean;
  version?: 'mobile' | 'desktop' | 'desktop-one-line';
}

interface TSXQuoteData {
  percentChange: number;
  price: number;
}

const TickerPrice: React.ComponentType<Props> = ({
  showLink = false,
  showListingKey = false,
  version = 'desktop',
}) => {
  const {
    currentCompany: {
      isUnlisted,
      ticker: { listingKey, marketKey, refinitivIdentificationCode },
    },
  } = useCurrentCompany();

  const isSsr = useIsSsr();

  const linkOut = useMemo(() => {
    if (isSsr) return '';
    if (showLink) return window.location.href.replace('/price-widget', '');

    return '';
  }, [showLink, isSsr]);

  const refinitivTokenQuery = useRefinitivTokenQuery();
  const [price, setPrice] = useState<number>();
  const [priceChange, setPriceChange] = useState<number>();
  const [currency, setCurrency] = useState<string>();
  const [tsxPrice, setTsxPrice] = useState<number>();
  const [tsxPriceChange, setTsxPriceChange] = useState<number>();

  const token = refinitivTokenQuery?.data?.token?.value;

  const params = {
    listingKey,
    marketKey,
    refinitivIdentificationCode: refinitivIdentificationCode || '',
    token: token || '',
  };

  const queryString = new URLSearchParams(params);

  const { data } = useSWR<RefinitivQuoteData, Error>(
    token && !isUnlisted
      ? `/api/refinitiv/quote-lists/get-simple-data?${queryString}`
      : null,
    fetcher,
    fetchOptions
  );

  const tsxMapping: { [key: string]: string } = {
    'asx:mth': 'MSG',
    'lse:cusn': 'CUSN',
  };

  const tsxListingKey = tsxMapping[`${marketKey}:${listingKey}`];

  // Temp fixes for dual listing with TSX data
  const { data: tsxData } = useSWR<TSXQuoteData, Error>(
    tsxListingKey ? `/api/tsx/get-quote?listingKey=${tsxListingKey}` : null,
    fetcher,
    fetchOptions
  );

  useEffect(() => {
    if (typeof tsxData?.price === 'number') {
      setTsxPrice(tsxData.price);
    }

    if (typeof tsxData?.percentChange === 'number') {
      setTsxPriceChange(tsxData.percentChange);
    }
  }, [tsxData]);

  useEffect(() => {
    let ignore = false;

    if (data && !ignore) {
      const cfLast = getFieldValue(data, 'CF_LAST');
      const pctchng = getFieldValue(data, 'PCTCHNG');
      const cfCurrency = getFieldValue(data, 'CF_CURRENCY');

      if (typeof cfLast === 'number') {
        setPrice(cfLast);
      }

      if (typeof pctchng === 'number') {
        setPriceChange(pctchng);
      } else {
        setPriceChange(0);
      }

      if (typeof cfCurrency === 'string') {
        setCurrency(cfCurrency);
      }
    }

    return () => {
      ignore = true;
    };
  }, [data]);

  const transformPriceString = (price: number, currency?: string) => {
    switch (currency) {
      case 'AUD':
        return price.toLocaleString('en-AU', {
          currency: 'AUD',
          maximumFractionDigits: 3,
          minimumFractionDigits: 2,
          style: 'currency',
        });

      case 'CAD':
        return price.toLocaleString('en-CA', {
          currency: 'CAD',
          maximumFractionDigits: 3,
          minimumFractionDigits: 2,
          style: 'currency',
        });

      case 'GBX':
        return `GBX ${price.toLocaleString('en-GB', {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })}`;
    }
  };

  if (isSsr) return null;

  const renderContent = () => {
    if (price !== undefined && priceChange !== undefined) {
      const priceString = transformPriceString(price, currency);
      if (version === 'desktop-one-line') {
        return (
          <div>
            {tsxPrice && (
              <div className="flex gap-2">
                {showListingKey && (
                  <Typography className="uppercase text-hubs-secondary">
                    {`TSXV:${tsxListingKey}`}
                  </Typography>
                )}
                <Typography
                  className="whitespace-nowrap text-hubs-primary"
                  variant="subtitle-2"
                >
                  {transformPriceString(tsxPrice, 'CAD')}
                </Typography>
                <PriceChange
                  priceChange={tsxPriceChange || 0}
                  typographyVariant="subtitle-2"
                />
              </div>
            )}
            <div className="flex gap-2">
              {showListingKey && (
                <Typography className="uppercase text-hubs-secondary">
                  {marketKey}:{listingKey}
                </Typography>
              )}
              <Typography
                className="whitespace-nowrap text-hubs-primary"
                variant="subtitle-2"
              >
                {priceString}
              </Typography>
              <PriceChange
                priceChange={priceChange}
                typographyVariant="subtitle-2"
              />
            </div>
          </div>
        );
      }

      if (version === 'mobile') {
        return (
          <div className="flex flex-col gap-2">
            {tsxPrice && (
              <div className="flex gap-2">
                {showListingKey && (
                  <Typography className="uppercase text-hubs-secondary">
                    {`TSXV:${tsxListingKey}`}
                  </Typography>
                )}
                <Typography
                  className="whitespace-nowrap text-hubs-primary"
                  variant="subtitle-2"
                >
                  {transformPriceString(tsxPrice, 'CAD')}
                </Typography>
                <PriceChange
                  priceChange={tsxPriceChange || 0}
                  typographyVariant="subtitle-2"
                />
              </div>
            )}
            <div className="flex gap-2">
              {showListingKey && (
                <Typography className="uppercase text-hubs-secondary">
                  {marketKey}:{listingKey}
                </Typography>
              )}
              <Typography
                className="whitespace-nowrap text-hubs-primary"
                variant="subtitle-2"
              >
                {priceString}
              </Typography>
              <PriceChange
                priceChange={priceChange}
                typographyVariant="subtitle-2"
              />
            </div>
          </div>
        );
      }
      return (
        <div className="flex min-w-[60px] flex-col items-end">
          <Typography
            className="whitespace-nowrap text-hubs-primary"
            variant="subtitle-2"
          >
            {priceString}
          </Typography>
          <PriceChange priceChange={priceChange} />
        </div>
      );
    }
  };

  if (showLink && linkOut) {
    return (
      <Link
        href={linkOut}
        target="_parent"
        onClick={() => analytics.track('price_widget_clicked')}
      >
        <div className="flex gap-1">
          {renderContent()}
          <ArrowTopRightOnSquareIcon className="h-4 w-4 pt-1 text-text-secondary" />
        </div>
      </Link>
    );
  }

  return renderContent();
};

export default TickerPrice;
