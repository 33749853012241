import { useCallback, useEffect, useMemo, useState } from 'react';
import analytics from '@analytics';
import { Menu } from '@headlessui/react';
import {
  EnvelopeIcon,
  MapPinIcon,
  PhoneIcon,
} from '@heroicons/react-v2/24/outline';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react-v2/24/solid';
import { LogoutIcon } from '@heroicons/react/outline';
import { UserCircleIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import cookie from 'cookie';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { maybeAddRelNofollow } from '@leaf/helpers';
import {
  Button,
  Typography,
  NavItem,
  StickySignupBanner,
  DUMMY_LOGO_URL,
} from '@leaf/ui';
import { useCurrentInvestorUserQuery } from '@/apollo/generated';
import { CurrentCompanyQuery } from '@/apollo/generated';
import CookieBanner from '@/components/cookie-manager/cookie-banner';
import HeadTag from '@/components/layouts/head-tag';
import MobileMenuButton from '@/components/layouts/root/mobile-menu-button';
import TickerPrice from '@/components/layouts/root/ticker-price';
import NotificationCenter from '@/components/notification-center';
import CloudinaryImage from '@/components/utils/cloudinary/image';
import EmailPopupModal from '@/components/website/email-popup-modal';
import { EMAIL_POPUP_CLOSED_COOKIE_NAME } from '@/components/website/email-popup-modal';
import ExitPreview from '@/components/website/exit-preview';
import SubscribeForm from '@/components/website/layout/subscribe-form';
import { useAlert } from '@/contexts/alert-context';
import { useCookieBanner } from '@/contexts/cookie-banner-context';
import { useCurrentCompany } from '@/contexts/current-company-context';
import { useWebsite } from '@/contexts/website-context';
import { useCreateTrackingUtmEvent } from '@/hooks/use-create-tracking-event';
import { FLAGS } from '@/hooks/use-feature-toggles';
import DreAmecImage from '@/public/images/amec-logo.png';
import PathzeroImage from '@/public/images/pathzero-logo.png';
import { logout } from '@/utils/auth/auth-helpers';
import {
  clearReturnToCookie,
  setReturnToCookie,
} from '@/utils/redirect-helpers';
import routes from '@/utils/routes';

type NavigationItem = {
  children?: NavigationItem[];
  current?: boolean;
  external?: boolean;
  href: string;
  id: string;
  name: string;
};

interface WebsiteLayoutProps {
  children?: React.ReactNode;
  hideFooterOnMobileAnnouncement?: boolean;
}

const WebsiteLayout: React.ComponentType<WebsiteLayoutProps> = ({
  children,
  hideFooterOnMobileAnnouncement,
}) => {
  const { formatAndShowError } = useAlert();

  const { currentCompany, isUK, translate } = useCurrentCompany();

  const {
    featuresEnabled,
    investorHub,
    isUnlisted,
    name,
    ticker: { listingKey, marketKey, marketListingKey },
  } = currentCompany;

  const { currentWebsite } = useWebsite();

  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const { setCookieBannerVisible } = useCookieBanner();

  const currentUserQuery = useCurrentInvestorUserQuery();

  const router = useRouter();
  const { asPath, pathname, query } = router;

  useCreateTrackingUtmEvent();

  useEffect(() => {
    const setupComplete =
      currentUserQuery.data?.currentInvestorUser?.confirmedAt &&
      currentUserQuery.data?.currentInvestorUser?.username;
    if (
      asPath &&
      asPath !== '/' &&
      !asPath.includes(routes.auth.signup.href()) &&
      !setupComplete
    )
      setReturnToCookie(asPath);
    if (setupComplete || (asPath && asPath.includes(routes.auth.signup.href())))
      clearReturnToCookie();
  }, [asPath, query, router, currentUserQuery.data?.currentInvestorUser]);

  type Child = NonNullable<
    NonNullable<
      NonNullable<CurrentCompanyQuery['currentCompany']>['publishedWebsite']
    >['parentNavMenuItems']
  >[0];

  const navMenuItems: NavigationItem[] | undefined = useMemo(() => {
    if (!currentWebsite?.parentNavMenuItems) return [];

    const isCurrentPage = (
      slug: string | null | undefined,
      url: string | null | undefined,
      children?: Array<{ page?: { slug?: string | null }; url?: string | null }>
    ): boolean => {
      if (children && children.length > 0) {
        return children.some((child) =>
          isCurrentPage(child.page?.slug, child.url)
        );
      }
      if (url) return false;
      if (slug === 'home' && pathname === '/_companies/[host]') return true;
      if (slug && pathname.includes(slug)) return true;
      if (url && pathname === url) return true;
      return query.slug === slug;
    };

    const createNavigationItem = (navMenuItem: Child): NavigationItem => ({
      children: navMenuItem?.children
        ?.slice()
        .sort((a, b) => (a?.position ?? 0) - (b?.position ?? 0))
        .map(createNavigationItem),
      current: isCurrentPage(
        navMenuItem?.page?.slug,
        navMenuItem?.url,
        navMenuItem?.children?.map((child) => ({
          page: { slug: child?.page?.slug },
          url: child?.url,
        }))
      ),
      external:
        navMenuItem?.openInNewTab || (!navMenuItem?.page && !!navMenuItem?.url),
      href:
        navMenuItem?.page?.slug === 'home'
          ? '/'
          : navMenuItem?.page?.slug
          ? `/${navMenuItem?.page?.slug}`
          : navMenuItem?.url ?? '',
      id: navMenuItem?.id ?? '',
      name: navMenuItem?.label ?? '',
    });

    return currentWebsite.parentNavMenuItems
      .slice()
      .sort((a, b) => (a?.position ?? 0) - (b?.position ?? 0))
      .filter(
        (item, index, self) =>
          index === self.findIndex((t) => t?.id === item?.id)
      )
      .map(createNavigationItem);
  }, [currentWebsite, pathname, query.slug]);

  const handleLogout = useCallback(async () => {
    try {
      await logout();

      window.location.assign(routes.index.href);
    } catch (error) {
      formatAndShowError(error);
    }
  }, [formatAndShowError]);

  const getCompanyDefaultEmail = () => {
    if (isUK) {
      return 'ukclients@investorhub.com';
    }

    return 'clients@investorhub.com';
  };

  const abTestSignUpButtonJoin = featuresEnabled.includes(
    FLAGS.abTestSignUpButtonJoin
  );

  const testSignUpText = (ab: boolean) => {
    return ab ? 'Join' : 'Sign up';
  };

  const [isEmailPopupOpen, setIsEmailPopupOpen] = useState(false);

  useEffect(() => {
    if (currentWebsite?.isEmailPopupModalEnabled) {
      const cookies = cookie.parse(document.cookie);
      const emailPopupClosed =
        cookies[EMAIL_POPUP_CLOSED_COOKIE_NAME] === 'true';

      if (!emailPopupClosed) {
        const timer = setTimeout(() => {
          setIsEmailPopupOpen(true);
          analytics.track('email_popup_modal_displayed');
        }, 15000); // Show after 15 seconds

        return () => clearTimeout(timer);
      }
    }
  }, [currentWebsite?.isEmailPopupModalEnabled]);

  function maybeShowTicker() {
    {
      /*
        Experiment for non-publicly-listed companies:
        + When company_profile is non publicly listed (isUnlisted)
        + then the <PriceTicker /> in navbar is hidden
      */
    }

    if (isUnlisted) return null;

    return (
      <>
        <TickerPrice showListingKey version="desktop-one-line" />
        <div className="h-8 w-px bg-hubs-dividers" />
      </>
    );
  }

  return (
    <>
      <HeadTag />
      <div className="flex min-h-screen flex-col">
        <header className="fixed inset-x-0 top-0 z-20 bg-hubs-background">
          <div className="relative mx-auto flex h-[72px] max-w-screen-xl items-center gap-4 px-4 sm:gap-6 sm:px-6">
            <div className="flex min-w-0 flex-1 items-center gap-4">
              <Link className="flex" href={routes.index.href}>
                {currentWebsite?.logoCloudinaryUrl ? (
                  <CloudinaryImage
                    alt="Logo"
                    className="h-[46px] w-auto"
                    height={50}
                    src={currentWebsite?.logoCloudinaryUrl}
                    width={200}
                  />
                ) : (
                  <Image
                    alt="Dummy logo"
                    height={46}
                    src={DUMMY_LOGO_URL}
                    width={46}
                  />
                )}
              </Link>
              {currentWebsite?.isFullWebsiteReplacement ? null : (
                <>
                  <div className="h-8 w-px bg-hubs-dividers" />
                  <div className="font-heading text-hubs-secondary">
                    {currentWebsite?.title ?? `${name} investor hub`}
                  </div>
                </>
              )}
            </div>

            <div
              className={clsx(
                'hidden min-w-0 flex-1 items-center justify-end gap-4 lg:flex',
                currentUserQuery.loading && 'opacity-0'
              )}
            >
              {maybeShowTicker()}

              {currentUserQuery.data?.currentInvestorUser ? (
                <>
                  <NotificationCenter />
                  <div className="h-8 w-px bg-hubs-dividers" />
                  <Menu as="div" className="relative">
                    <Menu.Button className="typography-subtitle-2 inline-flex items-center gap-2 text-hubs-primary">
                      <span data-test="my-account-btn-text">My account</span>
                      <UserCircleIcon className="h-6 w-6" />
                    </Menu.Button>
                    <Menu.Items className="absolute right-0 z-30 mt-2 min-w-64 origin-top-right divide-y overflow-hidden rounded-md border bg-hubs-background shadow-md">
                      <Menu.Item>
                        <div className="p-4">
                          <Typography className="text-hubs-secondary">
                            Signed in as
                          </Typography>
                          <Typography
                            className="mt-1 text-hubs-secondary"
                            variant="subtitle-2"
                          >
                            {currentUserQuery.data.currentInvestorUser.email}
                          </Typography>
                        </div>
                      </Menu.Item>
                      <Menu.Item>
                        <div>
                          <Link
                            className="typography-subtitle-2 flex h-[52px] items-center px-4 hover:bg-hubs-background-accent"
                            href={routes.accountSettings.href}
                          >
                            Account settings
                          </Link>
                        </div>
                      </Menu.Item>
                      {currentUserQuery.data.currentInvestorUser.username ? (
                        <Menu.Item>
                          <div>
                            <Link
                              className="typography-subtitle-2 flex h-[52px] items-center px-4 hover:bg-hubs-background-accent"
                              href={routes.profile.href(
                                currentUserQuery.data.currentInvestorUser
                                  .username
                              )}
                            >
                              Profile
                            </Link>
                          </div>
                        </Menu.Item>
                      ) : null}
                      <Menu.Item>
                        <button
                          className="typography-subtitle-2 flex h-[52px] w-full items-center gap-1 px-4 text-status-red hover:bg-hubs-background-accent"
                          data-test="sign-out-nav-btn"
                          type="button"
                          onClick={handleLogout}
                        >
                          <LogoutIcon className="h-6 w-6" />
                          Sign out
                        </button>
                      </Menu.Item>
                    </Menu.Items>
                  </Menu>
                </>
              ) : (
                <>
                  <Link href={routes.auth.signin.href()}>
                    <Button
                      color="grey"
                      variant="tertiary"
                      onClick={() => setReturnToCookie(router.asPath)}
                    >
                      Log in
                    </Button>
                  </Link>
                  <Link href={routes.auth.signup.href()}>
                    <Button
                      color="company"
                      testId="sign-up-nav-btn"
                      variant="primary"
                      onClick={() => setReturnToCookie(router.asPath)}
                    >
                      {testSignUpText(abTestSignUpButtonJoin)}
                    </Button>
                  </Link>
                </>
              )}
            </div>

            {currentUserQuery.data?.currentInvestorUser && (
              <div className="inline-flex lg:hidden">
                <NotificationCenter />
              </div>
            )}

            <MobileMenuButton
              handleLogout={handleLogout}
              isLoggedIn={!!currentUserQuery.data?.currentInvestorUser}
              navMenuItems={[
                ...navMenuItems,
                ...(currentWebsite?.isFullWebsiteReplacement
                  ? []
                  : [
                      {
                        current: false,
                        external: true,
                        href: investorHub?.website ?? routes.index.href,
                        name: `Back to ${name}`,
                      },
                    ]),
              ]}
              open={mobileNavOpen}
              setOpen={setMobileNavOpen}
            />
          </div>

          {/* This absolute div goes underneath the div following, so that the grey background goes all the way to the edges */}
          <div className="bg-company-accent text-company-accent-text">
            <div className="z-10 mx-auto hidden min-h-[48px] max-w-screen-xl items-center gap-4 px-4 sm:gap-6 sm:px-6 md:py-1.5 lg:flex">
              <div className="flex items-center gap-4 lg:gap-6 xl:gap-8">
                {navMenuItems.map((item) => (
                  <NavItem key={item.id} item={item} />
                ))}
              </div>
              {currentWebsite?.isFullWebsiteReplacement ? null : (
                <>
                  <span className="grow"></span>
                  <Link
                    className="flex items-center space-x-2 whitespace-nowrap font-body text-sm text-company-accent-text"
                    href={investorHub?.website ?? routes.index.href}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <div>Back to {name} </div>
                    <ArrowTopRightOnSquareIcon className="h-4 w-4" />
                  </Link>
                </>
              )}
            </div>
          </div>
        </header>
        <main
          className={clsx(
            'flex flex-1 flex-col pt-[72px] lg:mt-[48px]',
            hideFooterOnMobileAnnouncement
              ? 'bg-hubs-background-accent'
              : 'bg-hubs-background'
          )}
        >
          {children}
        </main>

        <footer
          className={clsx(
            'bg-company-accent py-16 text-company-accent-text',
            !currentUserQuery.loading &&
              !currentUserQuery.data?.currentInvestorUser &&
              hideFooterOnMobileAnnouncement &&
              'hidden md:block'
          )}
        >
          <div className="mx-auto mb-8 grid max-w-screen-xl grid-cols-5 gap-6 px-4 sm:px-6">
            <div className="col-span-5 flex flex-col items-center gap-5 sm:min-h-[132px] md:col-span-2 md:items-start lg:col-span-3">
              <div className="mb-2 flex h-[64px] w-[64px] items-center justify-center overflow-hidden rounded-full bg-hubs-background">
                {currentWebsite?.logoSquareCloudinaryUrl ? (
                  <CloudinaryImage
                    alt="Logo"
                    height={64}
                    quality={100}
                    src={currentWebsite?.logoSquareCloudinaryUrl}
                    width={64}
                  />
                ) : (
                  <Image
                    alt="Logo"
                    height={64}
                    quality={100}
                    src={DUMMY_LOGO_URL}
                    width={64}
                  />
                )}
              </div>

              <Typography
                className="text-center font-body sm:text-left"
                variant="subtitle-1"
              >
                {currentWebsite?.isFullWebsiteReplacement ? (
                  name
                ) : (
                  <span className="hidden sm:inline-block">
                    {currentWebsite?.title ?? `${name} investor hub`}
                  </span>
                )}
              </Typography>
              {listingKey === 'dre' ? (
                <div className="mx-auto flex flex-col items-center gap-4 text-center sm:mx-0 sm:flex-row sm:items-start sm:gap-6 sm:text-left">
                  <div className="flex shrink-0 flex-col gap-4">
                    <div className="flex items-start gap-1">
                      <MapPinIcon className="mt-1 h-4 w-4 opacity-65" />
                      <Typography className="text-center font-body sm:text-left">
                        Unit 1, 4 Burgay Court
                        <br />
                        Osborne Park, WA, 6017
                      </Typography>
                    </div>
                    <div className="flex flex-col gap-0.5">
                      <div className="flex items-start gap-1">
                        <PhoneIcon className="mt-0.5 h-4 w-4 opacity-65" />
                        <Typography className="text-center font-body sm:text-left">
                          +61 8 9473 8345
                        </Typography>
                      </div>
                      <div className="flex items-start gap-1">
                        <EnvelopeIcon className="mt-1 h-4 w-4 opacity-65" />
                        <Typography className="text-center font-body sm:text-left">
                          info@dreres.com.au
                        </Typography>
                      </div>
                    </div>
                  </div>
                  <div className="shrink-0">
                    <Image
                      alt={listingKey}
                      height={95}
                      quality={100}
                      src={DreAmecImage}
                      width={138}
                    />
                  </div>
                </div>
              ) : null}
              {listingKey === 'hm1' ? (
                <div className="mx-auto flex flex-col items-center gap-4 text-center sm:mx-0 sm:flex-row sm:items-start sm:gap-6 sm:text-left">
                  <div className="flex shrink-0 flex-col gap-4">
                    <Typography className="text-center font-body sm:text-left">
                      <b>Registered Office and Share Registry Office</b>
                      <br />
                      Hearts and Minds Investments Limited
                      <br /> c/- Boardroom Pty Limited
                      <br />
                      Level 8, 210 George Street Sydney NSW 2000
                      <br />
                      Telephone: <a href="tel:1300737760">
                        1300 737 760
                      </a> or <a href="tel:+61 2 9290 9600">+61 2 9290 9600</a>
                      <br />
                      Email:{' '}
                      <a href="mailto:heartsandminds@boardroomlimited.com.au">
                        heartsandminds@boardroomlimited.com.au{' '}
                      </a>
                    </Typography>

                    <div className="flex flex-col gap-0.5">
                      <Typography className="text-center font-body sm:text-left">
                        <b>Principal Place of Business:</b>
                        <br />
                        Suite 12.04, Level 12, Chifley Tower
                        <br />
                        2 Chifley Square
                        <br />
                        Sydney, NSW 2000
                        <br />
                        Email: <a href="mailto:ir@hm1.com.au">ir@hm1.com.au</a>
                      </Typography>
                    </div>
                  </div>
                  <div className="shrink-0">
                    <a
                      href="https://app.pathzero.com/public/report/hearts-and-minds-event"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <Image
                        alt={listingKey}
                        height={83}
                        quality={100}
                        src={PathzeroImage}
                        width={146}
                      />
                    </a>
                  </div>
                </div>
              ) : null}
            </div>

            {!currentUserQuery.loading &&
              !currentUserQuery.data?.currentInvestorUser && (
                <div className="col-span-5 md:col-span-3 lg:col-span-2">
                  <Typography
                    className="mb-2 text-center font-heading sm:text-left"
                    variant="display-small"
                  >
                    Stay in the loop.
                  </Typography>
                  <Typography className="mb-4 text-center font-body sm:text-left">
                    {/* Remove the trailing "." from the company name to avoid scenario ".." */}
                    Subscribe to receive {translate('announcements.lowercase')}{' '}
                    and updates from {name.replace(/\.$/, '')}.
                  </Typography>
                  <SubscribeForm event="subscribed_from_embed" />
                </div>
              )}
          </div>
          <div className="mx-auto mt-20 flex max-w-screen-xl flex-col-reverse items-center justify-between gap-6 border-t px-4 py-6 sm:flex-row sm:px-6">
            <Typography
              className="flex flex-wrap items-center justify-center gap-1 text-company-accent-text sm:justify-start"
              component="div"
              variant="body-small"
            >
              <a
                className="hover:opacity-90"
                href="https://www.investorhub.com"
                rel={`noreferrer ${maybeAddRelNofollow(marketListingKey)}`}
                target="_blank"
              >
                InvestorHub
              </a>
              <span>•</span>
              <a
                className="hover:opacity-90"
                href="https://www.investorhub.com/privacy-centre"
                rel={`noreferrer ${maybeAddRelNofollow(marketListingKey)}`}
                target="_blank"
              >
                Terms and conditions
              </a>
              <span>•</span>
              <a
                className="hover:opacity-90"
                href="https://www.investorhub.com/privacy-centre"
                rel={`noreferrer ${maybeAddRelNofollow(marketListingKey)}`}
                target="_blank"
              >
                Privacy policy
              </a>
              <span>•</span>
              <span
                className="hover:cursor-pointer hover:opacity-90"
                onClick={() => setCookieBannerVisible(true)}
              >
                Cookie settings
              </span>
              <span>•</span>
              <a
                className="hover:opacity-90"
                href={`mailto:${getCompanyDefaultEmail()}?subject=Technical%20Issue%20-%20${marketKey.toUpperCase()}%3A${listingKey.toUpperCase()}&body=Any%20data%20provided%20will%20be%20solely%20used%20to%20process%20your%20request%2C%20which%20may%20take%20up%20to%2030%20days.%0A%0APlease%20provide%20the%20details%20of%20your%20issue%20here%3A`}
                rel="noopener noreferer"
              >
                Technical issues?
              </a>
            </Typography>

            <div className="flex gap-6 fill-company-accent-text">
              {investorHub?.twitter && (
                <a
                  href={investorHub.twitter}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <svg
                    className="h-6 w-6 hover:opacity-90"
                    role="img"
                    viewBox="0 0 30 30"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <title>Twitter</title>
                    <path d="M26.37,26l-8.795-12.822l0.015,0.012L25.52,4h-2.65l-6.46,7.48L11.28,4H4.33l8.211,11.971L12.54,15.97L3.88,26h2.65 l7.182-8.322L19.42,26H26.37z M10.23,6l12.34,18h-2.1L8.12,6H10.23z" />
                  </svg>
                </a>
              )}
              {investorHub?.linkedin && (
                <a
                  href={investorHub.linkedin}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <svg
                    className="h-6 w-6 hover:opacity-90"
                    role="img"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <title>LinkedIn</title>
                    <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z" />
                  </svg>
                </a>
              )}
              {investorHub?.facebook && (
                <a
                  href={investorHub.facebook}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <svg
                    className="h-6 w-6 hover:opacity-90"
                    role="img"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <title>Facebook</title>
                    <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z" />
                  </svg>
                </a>
              )}
              {investorHub?.instagram && (
                <a
                  href={investorHub.instagram}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <svg
                    className="h-6 w-6 hover:opacity-90"
                    role="img"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <title>Instagram</title>
                    <path d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.585-.072-4.85c-.06-1.277-.256-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z" />
                  </svg>
                </a>
              )}
            </div>
          </div>
        </footer>
      </div>
      <CookieBanner />
      <ExitPreview />
      {!currentUserQuery.data?.currentInvestorUser &&
        currentWebsite?.isStickySignupBannerEnabled &&
        !pathname.includes('/announcements/') && (
          <StickySignupBanner
            isFullWebsiteReplacement={currentWebsite.isFullWebsiteReplacement}
            onCloseClicked={() => {
              analytics.track('sticky_signup_banner_closed');
            }}
            onJoinInvestorHubClicked={() => {
              analytics.track('sticky_signup_banner_join_investor_hub_clicked');
            }}
          />
        )}
      {!currentUserQuery.data?.currentInvestorUser &&
        currentWebsite?.isEmailPopupModalEnabled && (
          <EmailPopupModal
            companyName={currentCompany?.name}
            imageCloudinaryUrl={currentWebsite.emailPopupModalCloudinaryUrl}
            isOpen={isEmailPopupOpen}
            marketListingKey={marketListingKey}
            setIsOpen={setIsEmailPopupOpen}
          />
        )}
    </>
  );
};

export default WebsiteLayout;
