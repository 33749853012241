import parse from 'html-react-parser';
import capitalize from 'lodash/capitalize';
import { getCldOgImageUrl } from 'next-cloudinary';
import { NextSeo } from 'next-seo';
import Head from 'next/head';
import Script from 'next/script';
import { useCurrentCompany } from '@/contexts/current-company-context';
import { useWebsite } from '@/contexts/website-context';

const HeadTag: React.FC = () => {
  const { currentCompany } = useCurrentCompany();
  const { colorPalette, investorHub } = currentCompany;
  const { currentWebsite } = useWebsite();

  if (!currentCompany) {
    return null;
  }

  const page = currentWebsite?.currentPage;

  // CSS variables setup (unchanged)
  const companyPrimary = currentWebsite
    ? currentWebsite.themeColourPrimary
    : colorPalette?.primary.hsl || investorHub.colourPrimary;

  const fontVariables = currentWebsite
    ? `
    --font-heading: ${currentWebsite.themeFontTitle};
    --font-body: ${currentWebsite.themeFontBody};
    `
    : `
    --font-heading: Lora, serif;
    --font-body: Assistant, 'sans-serif';
    `;

  const lightMode = !currentWebsite?.isDarkMode;
  const websiteCssVariables = currentWebsite
    ? `
    --company-primary-text: ${currentWebsite.themeColourPrimaryText};
    --company-accent: ${currentWebsite.themeColourAccent};
    --company-accent-text: ${currentWebsite.themeColourAccentText};
    --hubs-primary: ${lightMode ? '#384250' : '#FFFFFF'};
    --hubs-secondary: ${lightMode ? '#6C737F' : '#E5E7EB'};
    --hubs-dividers: ${lightMode ? '#E5E7EB' : '#6C737F'};
    --hubs-background: ${lightMode ? '#FFFFFF' : '#000000'};
    --hubs-background-accent: ${lightMode ? '#F9FAFB' : '#333333'};
    --hubs-background-announcement: ${lightMode ? '#F9FAFB' : '#000000'};
    --hubs-chart-tooltip-bg: ${lightMode ? '#384250' : '#FFFFFF'};
    --hubs-chart-tooltip-text: ${lightMode ? '#F9FAFB' : '#384250'};
  `
    : '';

  const fallbackCssVariables =
    !currentWebsite && !colorPalette
      ? `
    --company-primary-text: ${investorHub.colourPrimaryText};
    --company-accent: ${investorHub.colourAccent};
    --company-accent-text: ${investorHub.colourAccentText};
    --hubs-primary: #384250;
    --hubs-secondary: #6C737F;
    --hubs-dividers: #E5E7EB;
    --hubs-background: #FFFFFF;
    --hubs-background-accent: #F9FAFB;
    --hubs-background-announcement: #F9FAFB;
  `
      : '';

  const cssVars = `
      :root {
        --company-primary: ${companyPrimary};
        ${websiteCssVariables}
        ${fallbackCssVariables}
        ${fontVariables}
      }
      `;

  // SEO setup

  // Title should be:
  // 1 the website title if it has been set
  // 2 the company name if it is a full site replacement
  // 3 the company name with ' investor hub' appended as default
  const defaultTitle = currentWebsite?.title
    ? currentWebsite.title
    : currentWebsite?.isFullWebsiteReplacement
    ? currentCompany.name
    : `${currentCompany.name} investor hub`;

  const logo = currentWebsite?.logoCloudinaryUrl || currentCompany.logoUrl;
  const squareLogo = currentWebsite?.logoSquareCloudinaryUrl || logo;

  const defaultDescription = `View share price, top shareholders, your shareholding, and interact with announcements for ${currentCompany.name}.`;

  const defaultImage =
    logo ||
    `https://australia-southeast1-leaf-343323.cloudfunctions.net/ogImage?marketKey=${currentCompany.ticker.marketKey}&listingKey=${currentCompany.ticker.listingKey}`;

  const pageTitle = page?.title
    ? `${capitalize(page.title)} | ${defaultTitle}`
    : defaultTitle;
  const pageDescription = page?.metaDescription || defaultDescription;
  const pageImage = page?.socialImageCloudinaryPublicId
    ? getCldOgImageUrl({
        src: page?.socialImageCloudinaryPublicId,
      })
    : defaultImage;

  const seoConfig = currentCompany
    ? {
        additionalLinkTags: squareLogo
          ? [
              {
                href: squareLogo,
                rel: 'icon',
              },
            ]
          : undefined,
        dangerouslySetAllPagesToNoFollow:
          process.env.NEXT_PUBLIC_ENVIRONMENT !== 'production',
        dangerouslySetAllPagesToNoIndex:
          process.env.NEXT_PUBLIC_ENVIRONMENT !== 'production',
        description: pageDescription,
        openGraph: {
          description: pageDescription,
          images: pageImage
            ? [
                {
                  alt: pageTitle,
                  height: 630,
                  url: pageImage,
                  width: 1200,
                },
              ]
            : undefined,
          title: pageTitle,
        },
        title: pageTitle,
        twitter: {
          cardType: pageImage ? 'summary_large_image' : 'summary',
          description: pageDescription,
          handle: `${investorHub.twitter || ''}`.replace(
            'https://twitter.com/',
            '@'
          ),
          image: pageImage ?? undefined,
          site: `${investorHub.twitter || ''}`.replace(
            'https://twitter.com/',
            '@'
          ),
          title: pageTitle,
        },
      }
    : {};

  return (
    <>
      <Head>
        {currentWebsite ? (
          <>
            <link href="https://fonts.googleapis.com" rel="preconnect" />
            <link
              crossOrigin="anonymous"
              href="https://fonts.gstatic.com"
              rel="preconnect"
            />
            <link
              href={`https://fonts.googleapis.com/css2?family=${currentWebsite.themeFontTitle?.replace(
                / /g,
                '+'
              )}:wght@400;500;600;700&display=swap`}
              rel="stylesheet"
            />
            <link
              href={`https://fonts.googleapis.com/css2?family=${currentWebsite.themeFontBody?.replace(
                / /g,
                '+'
              )}:wght@400;500;600;700&display=swap`}
              rel="stylesheet"
            />
          </>
        ) : null}
        <style type="text/css">{cssVars}</style>
        {currentWebsite && currentWebsite.headTagContent && (
          <>{parse(currentWebsite.headTagContent)}</>
        )}
      </Head>
      <NextSeo {...seoConfig} />

      {currentWebsite?.googleAnalyticsId && (
        <>
          <Script
            src={`https://www.googletagmanager.com/gtag/js?id=${currentWebsite.googleAnalyticsId}`}
            strategy="afterInteractive"
          />
          <Script id="google-analytics" strategy="afterInteractive">
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${currentWebsite.googleAnalyticsId}');
            `}
          </Script>
        </>
      )}
    </>
  );
};

export default HeadTag;
